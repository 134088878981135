<template>
  <teleport to="#app">
  <div v-if="showModal" class="modal modal-lg">
    <div class="modal-content">
      <div class="d-flex justify-content-end">
        <button class="btn-close" @click="closeModal"></button>
      </div>

      <slot></slot>
      <slot name="footer"></slot>

    </div>

  </div>
  </teleport>
</template>

<script>
export default {
  name: "ModalComponent",
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
  watch: {
    // showModal: function (val) {
    //   if (val) {
    //     let posDetails = document.getElementById("pos-details");
    //     let posProducts = document.getElementById("pos-products");
    //     if (posDetails) {
    //       posDetails.style.zIndex = "-1";
    //     }
    //     if (posProducts) {
    //       posProducts.style.zIndex = "-1";
    //     }
    //   } else {
    //     let posDetails = document.getElementById("pos-details");
    //     let posProducts = document.getElementById("pos-products");
    //     if (posDetails) {
    //       // remove the z-index
    //        posDetails.style.zIndex = 'initial'
    //     }
    //     if (posProducts) {
    //       posProducts.style.zIndex = 'initial'
    //     }
    //   }
    // }
  }
};
</script>

<style scoped>
/* Style your modal here, e.g. center it on the screen */
.modal {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  max-height: 600px!important;
  max-width: 900px;
  border-radius: 4px;
}

.modal-close {
  margin-top: 10px;
}
</style>