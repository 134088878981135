<template>
  <div>
    <!-- Campo de Entrada -->
    <input type="text" readonly @click="openModal" :value="selectedRecordName" class="form-control">

    <!-- Modal para Seleção de Registros -->
    <div class="modal" v-if="showModal">
      <div class="modal-custom">
        <div class="modal-content">
          <Vue3EasyDataTable :headers="field.headers" :items="records" @click-row="selectRecord">
          </Vue3EasyDataTable>
        </div>
        <div class="modal-footer">
          <button @click="closeModal" class="btn btn-secondary">Fechar</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/boostrap/apiConfig";
import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
  name: 'RecordFinder',
  components: {Vue3EasyDataTable},
  props: {
    initialValue: {
      type: Number
    },
    field: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      records: [],
      selectedRecord: null,
      selectedRecordName: '',
      alreadyLoaded: false
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
      this.fetchRecords();
    },
    closeModal() {
      this.showModal = false;
    },
    fetchRecords() {
      if (this.alreadyLoaded) {
        return
      }
      let base_url = this.field.list
      let base64 = btoa(base_url)
      axios.get('/recordFinder/' + base64)
          .then(response => {
            this.records = response.data;
            this.checkInitialValues();
          })
          .catch(error => {
            console.error('Error fetching records:', error);
          }).finally(() => {
        this.alreadyLoaded = true
      })
    },
    selectRecord(record) {
      this.selectedRecord = record;
      this.selectedRecordName = record.name;
      this.closeModal()
      this.$emit('record-selected', record.id, this.field.name);
    },
    selectRecordInitialValue(record) {
      console.log('selectRecordInitialValue:', record)
      this.selectedRecord = record;
      this.selectedRecordName = record.name;
      this.$emit('record-selected', record.id, this.field.name);
    },
    checkInitialValues() {
      if (this.initialValue) {
        const record = this.records.find(r => r.id === this.initialValue);
        if (record) {
          this.selectRecordInitialValue(record);
        }
      }
    }
  },
  mounted() {
    if (this.initialValue) {
      console.log('initialValue:', this.initialValue);
      this.fetchRecords()
    }
  }
};
</script>

<style scoped>
/* Estilos Personalizados */

/* Estilização do Campo de Entrada */
input[readonly] {
  cursor: pointer;
  background-color: #f8f9fa; /* Cor de fundo suave */
  border-color: #ced4da; /* Cor da borda */
}

/* Estilização do Modal */
.modal-custom {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 1050;
  width: 80%;
  max-width: 800px; /* Largura máxima do modal */
}

.modal-content {
  overflow-y: auto;
  max-height: 400px; /* Altura máxima do conteúdo do modal */
}

/* Estilização da Lista de Registros */
.record-list-item {
  cursor: pointer;
  transition: background-color 0.2s;
}

.record-list-item:hover {
  background-color: #f8f9fa; /* Cor de fundo ao passar o mouse */
}
</style>
